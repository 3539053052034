
.flip-card-inner {
  transition: transform 1.2s;  /* Increased the rotation speed from 0.8s to 1.2s for a slower effect */
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flip-card-back {
  transform: rotateY(180deg);
}
